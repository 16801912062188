<template>
  <header class="navbar navbar-expand-md bd-navbar justify-content-between sticky-top" style="min-height: 50px;">
    <ul class="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0">
      <!-- <router-link :to="'/'"> -->
      <li @click="goHome" style="cursor: pointer;" v-if="currentUser">Калькулятор</li>
      <!-- </router-link> -->
      <router-link to="/types" v-if="
        $store.state.activityType != '' && $store.state.productionType != '' && route.path != '/' && route.path != '/types' && route.path != '/metodics' && currentUser
      ">
        <li v-if="currentUser && (Object.keys($store.state.blackMetProcess).length === 0 && $store.state.blackMetProcess.constructor === Object) && $store.state.activityType != 'Черная металлургия' && $store.state.activityType != 'Производство минеральных удобрений' && $store.state.activityType != 'Добыча и обогащение железных руд' && $store.state.productionType != 'Производство кирпича'">Типы расчетов</li>
      </router-link>
    </ul>
    <span class="position-absolute top-50 start-50 translate-middle d-none d-lg-block" v-if="currentUser">
      <span class="head-title col" v-if="route.path == '/metodics'">Методики расчета</span>
      <span class="head-title col" v-else-if="route.path == '/types'">Тип расчета</span>
      <span class="head-title col" v-else-if="aType != '' && pType == ''">{{ aType }}</span>
      <span class="head-title col" v-else-if="aType != '' && pType != ''">{{ aType }} - {{ pType }}</span>
      <span class="head-title col" v-else>Выбор отрасли</span>
    </span>
    <ul class="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0 me-5" v-if="currentUser">
      <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          {{ currentUser.username }}
        </a>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" style="color: black !important" href="#" @click.prevent="logOut">Выйти</a></li>
          <!-- <li class="dropdown-item"><router-link to="/statistic-info" style="color: black !important">Статистика</router-link></li> -->
        </ul>
      </li>
    </ul>
    <ul v-else class="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0 me-5 my-2">
      <li>
        <img src="/img/logo/minprom_logo.png">
      </li>
      <li>
        <img src="/img/logo/fgau_logo.png">
      </li>
      <!-- <router-link to="/register">
        <li class="nav-item">Регистрация</li>
      </router-link>
      <router-link to="/login">
        <li class="nav-item">Войти</li>
      </router-link> -->
    </ul>
  </header>
</template>

<script>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { computed, onMounted } from 'vue';
export default {
  name: "Navbar",
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    function goHome() {
      if (route.path != '/') {
        store.commit("setActivityType", "");
        store.commit("setProductionType", "");
        store.commit("setProduction", { k: "0", l: "zero" });
        router.push("/")
      }
    }
    const aType = computed(() => {
      return store.getters.activ
    })
    const pType = computed(() => {
      return store.getters.prodType
    })

    const currentUser = computed(() => {
      return store.state.auth.user;
    })
    onMounted(() => {
      if (!currentUser.value) {
        // router.push('/');
      }
    })
    function logOut() {
      store.dispatch('auth/logout');
      store.commit("setProduction", { k: "0", l: "zero" });
      store.commit("setProductionType", "");
      store.commit("setActivityType", "");
      router.push('/login');
    }

    return {
      goHome, route, aType, pType, currentUser, logOut
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
$calculate-for-device-button-color: #7a6d49;
$hover-button-color: #ffffff;

$custom-btn-type-two-color: #6b6b6b;
$hover-custom-btn-type-two-color: #ffffff;

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #0d6efd;
}

.bd-navbar {
  padding: 0 !important;
  background-color: #7952b3;
  margin-bottom: 1rem;
  color: white;
}

ul.navbar-nav a {
  color: white !important;
}

ul.navbar-nav a:hover {
  color: rgb(240, 227, 227) !important;
}

.navbar {
  // position: sticky !important;
  top: 0;
  z-index: 1023 !important;
}

.navbar {
  background: linear-gradient(to right, #295575, #4CA6C6, #75DFB2) !important;
}

.head-title {
  font-size: 18px;
  font-weight: 700;
}

ul.navbar-nav li {
  font-size: 14px;
}
.nav-link.dropdown-toggle{
  margin-right: 50px;
}

</style>

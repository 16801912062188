import { createRouter, createWebHistory } from "vue-router"
import Types from "../views/Types.vue"
import Home from "../views/Home.vue"
import TheTypeOne from "../views/TheTypeOne.vue"
import TheTypeTwo from "../views/TheTypeTwo.vue"
import TheTypeThree from "../views/TheTypeThree.vue"
import TheTypeOneCement from "../views/TheTypeOneCement.vue"
import TheTypeTwoCement from "../views/TheTypeTwoCement.vue"
import TheTypeThreeCement from "../views/TheTypeThreeCement.vue"
import Register from "../views/Register.vue"
import Login from "../views/Login.vue"
import Statistic from "../views/Statistic.vue"
import TheMetodics from "../views/TheMetodics.vue"

import TheMetallurgyFirstStep from "../views/TheMetallurgyFirstStep.vue"
import TheColorMetalCalculate from "../views/TheColorMetalCalculate.vue"
import PasswordReset from "../views/PasswordReset.vue"
import NewPassword from "../views/NewPassword.vue"
import TheNewBlackMetalCalc from "../views/TheNewBlackMetalCalc.vue"
import TheAmmoniaCalc from "../views/TheAmmoniaCalc.vue"
import TheAmmoniaCalcTwo from "../views/TheAmmoniaCalcTwo.vue"
import TheNitricAcid from "../views/TheNitricAcid.vue"
import TheBrickProduction from "../views/TheBrickProduction.vue"
import ExtractEnrichIronOres from '../views/ExtractEnrichIronOres.vue'

import store from "../store"

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/type-one",
		name: "TheTypeOne",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: TheTypeOne,
	},
	{
		path: "/type-two",
		name: "TheTypeTwo",
		component: TheTypeTwo,
		meta: {
			carbonate: false,
		},
	},
	{
		path: "/type-three",
		name: "TheTypeThree",
		component: TheTypeThree,
		meta: {
			carbonate: true,
		},
	},
	{
		path: "/types",
		name: "Types",
		component: Types,
	},
	{
		path: "/type-one-cement",
		name: "TheTypeOneCement",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: TheTypeOneCement,
	},
	{
		path: "/type-two-cement",
		name: "TheTypeTwoCement",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: TheTypeTwoCement,
	},
	{
		path: "/type-three-cement",
		name: "TheTypeThreeCement",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: TheTypeThreeCement,
		meta: {
			carbonate: true,
		},
	},
	{
		path: "/brick-production",
		name: "TheBrickProduction",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: TheBrickProduction,
		meta: {
			carbonate: true,
		},
	},
	{
		path: "/register",
		name: "Register",
		component: Register,
	},
	{
		path: "/login",
		name: "Login",
		component: Login,
	},
	{
		path: "/statistic-info",
		name: "Statistic",
		component: Statistic,
	},
	{
		path: "/metodics",
		name: "TheMetodics",
		component: TheMetodics,
	},
	
	{
		path: "/metallurgy",
		name: "TheMetallurgyFirstStep",
		component: TheMetallurgyFirstStep
	},
	{
		path: "/new-ferrous-metallurgy-calculate",
		name: "TheNewBlackMetalCalc",
		component: TheNewBlackMetalCalc,
	},
	{
		path: "/extraction-and-enrichment-of-iron-ores",
		name: "ExtractEnrichIronOres",
		component: ExtractEnrichIronOres,
	},
	
	{
		path: "/non-ferrous-metallurgy-calculate",
		name: "TheColorMetalCalculate",
		component: TheColorMetalCalculate,
	},
	{
		path: "/password-reset",
		name: "PasswordReset",
		component: PasswordReset,
	},
	{
		path: "/new-password/:hash",
		name: "NewPassword",
		component: NewPassword,
	},
	{
		path: "/ammonia",
		name: "TheAmmoniaCalc",
		component: TheAmmoniaCalc
	},
	{
		path: "/ammonia-two",
		name: "TheAmmoniaCalcTwo",
		component: TheAmmoniaCalcTwo
	},
	{
		path: "/nitric-acid",
		name: "TheNitricAcid",
		component: TheNitricAcid
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach(async (to, from) => {
	if (
		to.path != "/" &&
		to.path != "/login" &&
		to.path != "/register" &&
		to.path != "/statistic-info" &&
		to.path != "/password-reset" &&
		to.name != 'NewPassword'
	) {
		if (
			store.state.activityType == "" ||
			store.state.productionType == ""
		) {
			return { name: "Home" }
		}
	}
	if (from.path == "/metodics" && to.path == "/") {
		store.commit("setActivityType", "")
	}
})
router.beforeEach((to, from, next) => {
	const publicPages = ["/login", "/register", "/password-reset", "/new-password/:hash"]
	const authRequired = !publicPages.includes(to.path)
	const loggedIn = localStorage.getItem("user")

	// trying to access a restricted page + not logged in
	// redirect to login page
	if (authRequired && !loggedIn && to.name != 'NewPassword') {
		next("/login")
	} else {
		next()
	}
})

export default router

<template>
    <tr :class="{ 'table-secondary': props.data.main, 'table-light': !props.data.main }">
        <td class="fw-bolder" v-if="props.data.first" :rowspan="props.len">{{ props.title }}</td>
        <td :class="{ 'fw-bolder': props.data.main }">{{ props.data.name }}</td>
        <td>{{ props.data.units }}</td>
        <td v-for="year in props.years" :key="year">
            <!-- <input type="number" v-model="res.consumption[year]"> -->
            <FormKit label="" type="number" validation="number" :validation-messages="{
                number: 'Должно быть число.',
            }" v-model="res.consumption[year]" name="" placeholder="Объем" inner-class="my-width"
                outer-class="my-outer-new" />
        </td>
        <td>
            <p class="qwerty-new grey mb-0">{{ res.consumptionMid.toFixed(2) }}</p>
        </td>
        <td>{{ props.data.koeff ? props.data.koeff : '-' }}</td>
        <!-- <template v-for="year in props.years" :key="year">
            <td>
                <p class="qwerty grey mb-0">{{ res.emissions[year].toFixed(5) }}</p>
            </td>
            <td>
                <p class="qwerty grey mb-0">{{ props.data.percents[year].toFixed(1) + " %" }}</p>
            </td>
        </template> -->
        <td v-for="year in props.years" :key="year">
            <p class="qwerty-new grey mb-0">{{ res.emissions[year].toFixed(5) }}</p>
        </td>
        <td>
            <p class="qwerty-new grey mb-0">{{ res.emissionsMid.toFixed(2) }}</p>
        </td>
        <td v-for="year in props.years" :key="year">
            <p class="qwerty-new grey mb-0">{{ props.data.percents[year].toFixed(1) + " %" }}</p>
        </td>
    </tr>
</template>

<script setup>
import { reactive, watchEffect } from 'vue';


const props = defineProps({
    years: Array,
    data: Object,
    title: String,
    len: Number
})

const emit = defineEmits(['sendData'])

const res = reactive({
    name: props.data.name,
    units: props.data.units,
    consumption: props.data.volume,
    consumptionMid: props.data.volumeMid,
    emissions: props.data.emiss,
    emissionsMid: props.data.emissMid
}) 
for (let i = 0; i < props.years.length; i++) {
    res.consumption[props.years[i]] = 0
    res.emissions[props.years[i]] = 0
}

watchEffect(() => {
    res.consumptionMid = 0
    res.emissionsMid = 0
    for (let i = 0; i < props.years.length; i++) {
        res.emissions[props.years[i]] = res.consumption[props.years[i]] * props.data.koeff
        res.consumptionMid += Number(res.consumption[props.years[i]])
        res.emissionsMid += Number(res.emissions[props.years[i]])
    }
    res.consumptionMid = res.consumptionMid / props.years.length
    res.emissionsMid = res.emissionsMid / props.years.length
    emit('sendData', res)
})
</script>

<style>
p.qwerty-new {
    width: 100%;
    border: 1px solid #dcd9d9;
    border-radius: 4px;
    padding: 0.5rem;
    background-color: #f2f2f2;
    text-align: left;
    /* margin-top: -15px; */
}

.my-outer-new {
    margin: 0 !important;
}
</style>
import { main } from "@popperjs/core";

export const ExtrAndEnrichIronOresData = {
  "Открытая добыча": {
    ip1: 0.0032,
    ip2: 0.0024,
    image: "url(/img/black-metallurgy/koks.png)",
    inputDataArr: [
      {
        name: "Дизельное топливо",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1465,
        main: true,
        first: true
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 3.1465,
      //   main: false,
      //   first: false
      // },
      // {
      //   name: "в т.ч. на транспортировку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 3.1465,
      //   main: false,
      //   first: false
      // },
      // {
      //   name: "Дизельное топливо на приготовление взрывчатых веществ",
      //   volume: {},
        // volumeMid: 0,
      //   emiss: {},
        // emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 0,
      //   main: false,
      //   first: false
      // },
      {
        name: "Мазут",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1099,
        main: true,
        first: false
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
        // volumeMid: 0,
      //   emiss: {},
        // emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 0,
      //   main: false,
      //   first: false
      // },
      {
        name: "Природный газ",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "тыс. м3",
        koeff: 1.9053,
        main: true,
        first: false
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
        // volumeMid: 0,
      //   emiss: {},
        // emissMid: 0,
      //   percents: {},
      //   units: "тыс. м3",
      //   koeff: 0,
      //   main: false,
      //   first: false
      // },
      {
        name: "Электроэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: true,
        first: false
      },
      {
        name: "в т.ч. на водоотведение (откачка дренажной воды из карьера и водопонизительных скважин карьера)",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false,
        first: false
      },
      {
        name: "в т.ч. на транспортировку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false,
        first: false
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "МВт*ч",
      //   koeff: 0.504,
      //   main: false,
      //   first: false
      // },
      {
        name: "Теплоэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "Гкал",
        koeff: 0.27,
        main: true,
        first: false
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "Гкал",
      //   koeff: 0.27,
      //   main: false,
      //   first: false
      // },
    ],
    outputDataArr: [
      {
        name: "Добываемая горная масса",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т (сухой вес)",
        koeff: 0,
        main: true,
        first: true
      },
    ]
  },
  "Подземная добыча": {
    ip1: 0.0226,
    ip2: 0.0146,
    image: "url(/img/black-metallurgy/koks.png)",
    inputDataArr: [
      {
        name: "Дизельное топливо",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1465,
        main: true,
        first: true
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 3.1465,
      //   main: false
      // },
      // {
      //   name: "в т.ч. на транспортировку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 3.1465,
      //   main: false
      // },
      // {
      //   name: "Дизельное топливо на приготовление взрывчатых веществ",
      //   volume: {},
        // volumeMid: 0,
      //   emiss: {},
        // emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 0,
      //   main: false
      // },
      {
        name: "Мазут",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1099,
        main: true
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
        // volumeMid: 0,
      //   emiss: {},
        // emissMid: 0,
      //   percents: {},
      //   units: "т",
      //   koeff: 0,
      //   main: false
      // },
      {
        name: "Природный газ",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "тыс. м3",
        koeff: 1.9053,
        main: true
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
        // volumeMid: 0,
      //   emiss: {},
        // emissMid: 0,
      //   percents: {},
      //   units: "тыс. м3",
      //   koeff: 0,
      //   main: false
      // },
      {
        name: "Электроэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: true
      },
      {
        name: "в т.ч. на водоотведение (в т.ч. на водоотведение (откачка дренажной воды из шахты))",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false
      },
      {
        name: "в т.ч. на транспортировку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "МВт*ч",
      //   koeff: 0.504,
      //   main: false
      // },
      {
        name: "Теплоэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "Гкал",
        koeff: 0.27,
        main: true
      },
      // {
      //   name: "в т.ч. на сушку",
      //   volume: {},
      //   volumeMid: 0,
      //   emiss: {},
      //   emissMid: 0,
      //   percents: {},
      //   units: "Гкал",
      //   koeff: 0.27,
      //   main: false
      // },
    ],
    outputDataArr: [
      {
        name: "Добываемая горная масса",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т (сухой вес)",
        koeff: 0,
        main: true,
        first: true
      },
    ]
  },
  "Сухое обогащение": {
    ip1: 0.01,
    ip2: 0.0069,
    image: "url(/img/black-metallurgy/koks.png)",
    inputDataArr: [
      {
        name: "Железная руда на обогащение",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т (сухой вес)",
        koeff: 0,
        main: true,
        first: true
      },
      {
        name: "Содержание Fe общ.",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "%",
        koeff: 0,
        main: false
      },
      {
        name: "Дизельное топливо",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1465,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1465,
        main: false
      },
      {
        name: "Мазут",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1099,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1099,
        main: false
      },
      {
        name: "Природный газ",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "тыс. м3",
        koeff: 1.9053,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "тыс. м3",
        koeff: 1.9053,
        main: false
      },
      {
        name: "Электроэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false
      },
      {
        name: "в т.ч. на перекачку хвостов обогащения",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false
      },
      {
        name: "Теплоэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "Гкал",
        koeff: 0.27,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "Гкал",
        koeff: 0.27,
        main: false
      },
    ],
    outputDataArr: [
      {
        name: "Железорудный концентрат",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т (сухой вес)",
        koeff: 0,
        main: true,
        first: true
      },
      {
        name: "Содержание Fe общ. ",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "%",
        koeff: 0,
        main: false,
        first: false
      },
    ],
  },
  "Мокрое обогащение": {
    ip1: 0.0612,
    ip2: 0.0429,
    image: "url(/img/black-metallurgy/koks.png)",
    inputDataArr: [
      {
        name: "Железная руда на обогащение",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т (сухой вес)",
        koeff: 0,
        main: true,
        first: true
      },
      {
        name: "Содержание Fe общ.",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "%",
        koeff: 0,
        main: false
      },
      {
        name: "Дизельное топливо",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1465,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1465,
        main: false
      },
      {
        name: "Мазут",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1099,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т",
        koeff: 3.1099,
        main: false
      },
      {
        name: "Природный газ",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "тыс. м3",
        koeff: 1.9053,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "тыс. м3",
        koeff: 1.9053,
        main: false
      },
      {
        name: "Электроэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false
      },
      {
        name: "в т.ч. на перекачку хвостов обогащения",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "МВт*ч",
        koeff: 0.504,
        main: false
      },
      {
        name: "Теплоэнергия",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "Гкал",
        koeff: 0.27,
        main: true
      },
      {
        name: "в т.ч. на сушку",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "Гкал",
        koeff: 0.27,
        main: false
      },
    ],
    outputDataArr: [
      {
        name: "Железорудный концентрат",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "т (сухой вес)",
        koeff: 0,
        main: true,
        first: true
      },
      {
        name: "Содержание Fe общ. ",
        volume: {},
        volumeMid: 0,
        emiss: {},
        emissMid: 0,
        percents: {},
        units: "%",
        koeff: 0,
        main: false
      },
    ],
  },
};

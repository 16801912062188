<template>
    <div class="sCont mb-5 rounded rounded-3">
        <div class="input_container table-responsive mx-3">
            <h3 class="subTitle">Ввод данных</h3>
            <table class="table table-bordered align-middle table-responsive table-sm">
                <!-- <TableHeaderComponent :years="years" /> -->
                <thead class="table-light">
                    <tr class="table-primary">
                        <th class="align-middle" scope="col" rowspan="2">Вид потока (вход/выход)</th>
                        <th class="align-middle" scope="col" rowspan="2">Вид ресурса
                            (сырье, материалы, топливо, энергоресурсы, продукция)</th>
                        <th class="align-middle" scope="col" rowspan="2">Единицы измерения</th>
                        <th class="align-middle" scope="col" :colspan="years.length + 1">Суммарный расход / производство
                            по годам</th>


                        <th class="align-middle" scope="col" rowspan="2">Коэффициенты выбросов СО2</th>
                        <th class="align-middle" scope="col" :colspan="years.length + 1">Выбросы, т СО2</th>

                        <th class="align-middle" scope="col" :colspan="years.length">Выбросы в процентах</th>
                    </tr>
                    <tr class="table-primary">
                        <th class="align-middle" scope="col" v-for="(year, index) in years" :key="index">{{ year }}</th>
                        <th class="align-middle" scope="col">Среднее</th>
                        <th class="align-middle" scope="col" v-for="(year, index) in years" :key="index">{{ year }}</th>
                        <th class="align-middle" scope="col" rowspan="2">Среднее</th>
                        <th class="align-middle" scope="col" v-for="(year, index) in years" :key="index">Доля в % {{
                            year }}г.</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRowComponent v-for="(vol, index) in inputDataArr" :data="vol" :years="years" title="Вход"
                        :len="inputDataArr.length" @sendData="getData" />
                    <TableRowComponent v-for="(vol, index) in outputDataArr" :data="vol" :years="years" title="Выход"
                        :len="outputDataArr.length" @sendData="getData" />
                </tbody>
            </table>
        </div>
        <hr>
        <button type="button" class="btn btn-warning btn-lg calcbtn" @click="openModal">Показать результат</button>
        <div class="big-chart">
            <Bar :chart-data="chartData" :chart-options="chartOptions" :width="width1" :height="height1"
                ref="BarMainChart" />
        </div>
    </div>
    <div class="modal fade" id="exampleModal" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-centered modal-fullscreen-md-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">Результаты расчетов</h1>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeModal"></button>
                </div>
                <div class="modal-body">
                    <table v-if="modalValue !== null" class="table table-bordered">
                        <thead class="table-light">
                            <tr>
                                <th :rowspan="modalValue !== null ? Object.getOwnPropertyNames(modalValue).length : 0">
                                    Процесс</th>
                                <th>Расчет интенсивности выбросов</th>
                                <th v-for="year in years" :key="year">{{ year }}</th>
                                <th>Среднее</th>
                                <th v-for="year in years" :key="year">{{ year }} в %</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="text-center m-auto align-middle"
                                    :rowspan="modalValue !== null ? Object.getOwnPropertyNames(modalValue).length : 0">
                                    {{ main + ' - ' + process }}</td>
                                <td>Прямые выбросы, т СО2</td>
                                <td v-for="year in years" :key="year">{{ modalValue.directEmission[year].toFixed(0) }}
                                </td>
                                <!-- среднее -->
                                <td>{{ modalValue.directEmissionMid.toFixed(0) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.directEmissionP[year].toFixed(1) }}%
                                </td>
                            </tr>
                            <tr>
                                <td>Косвенные выбросы от электроэнергии, т СО2</td>
                                <td v-for="year in years" :key="year">{{ modalValue.electroEmission[year].toFixed(0) }}
                                </td>
                                <td>{{ modalValue.electroEmissionMid.toFixed(0) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.electroEmissionP[year].toFixed(1)
                                }}%
                                </td>
                            </tr>
                            <tr>
                                <td>Косвенные выбросы от тепловой энергии, т СО2</td>
                                <td v-for="year in years" :key="year">{{ modalValue.teplotEmission[year].toFixed(0) }}
                                </td>
                                <td>{{ modalValue.teplotEmissionMid.toFixed(0) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.teplotEmissionP[year].toFixed(1) }}%
                                </td>
                            </tr>
                            <tr>
                                <td>Всего выбросы, т СО2</td>
                                <td v-for="year in years" :key="year">{{ modalValue.total[year].toFixed(0) }}</td>
                                <td>{{ modalValue.totalMid.toFixed(0) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.totalP[year].toFixed(0) }}%</td>
                            </tr>
                            <tr class="table-primary">
                                <td>Продукт (железорудный концентрат), т</td>
                                <td v-for="year in years" :key="year">{{ modalValue.productWeight[year] }}</td>
                                <td>{{ modalValue.productWeightMid.toFixed(1) }}</td>
                                <td v-for="year in years" :key="year"></td>
                            </tr>
                            <tr>
                                <td>Удельные прямые выбросы, т СО2/т</td>
                                <td v-for="year in years" :key="year">{{ modalValue.udelDirectEmission[year].toFixed(4)
                                    }}</td>
                                <td>{{ modalValue.udelDirectEmissionMid.toFixed(4) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.udelDirectEmissionP[year].toFixed(1)
                                    }}%</td>
                            </tr>
                            <tr>
                                <td>Удельные выбросы от электроэнергии, т СО2/т</td>
                                <td v-for="year in years" :key="year">{{ modalValue.udelElectroEmission[year].toFixed(4)
                                    }}</td>
                                <td>{{ modalValue.udelElectroEmissionMid.toFixed(4) }}</td>
                                <td v-for="year in years" :key="year">{{
                                    modalValue.udelElectroEmissionP[year].toFixed(1)
                                }}%</td>
                            </tr>
                            <tr>
                                <td>Удельные выбросы от тепловой энергией, т СО2/т</td>
                                <td v-for="year in years" :key="year">{{ modalValue.udelTeploEmission[year].toFixed(4)
                                    }}</td>
                                <td>{{ modalValue.udelTeploEmissionMid.toFixed(4) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.udelTeploEmissionP[year].toFixed(1)
                                    }}%</td>
                            </tr>
                            <tr class="table-success">
                                <td>Интенсивность выбросов (бенчмарк), т СО2/т </td>
                                <td v-for="year in years" :key="year">{{ modalValue.intensity[year].toFixed(4) }}</td>
                                <td>{{ modalValue.intensityMid.toFixed(4) }}</td>
                                <td v-for="year in years" :key="year">{{ modalValue.intensityP[year].toFixed(1) }}%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="openSecondModal">График</button>
                    <button type="button" class="btn btn-dark" @click="exportDocx">Скачать отчёт</button>
                    <button type="button" class="btn btn-secondary" @click="closeModal">Закрыть</button>

                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="exampleModalToggle2">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">График - Удельные выбросы, т СО2-экв. / т
                        Ал.
                    </h1>
                    <button type="button" class="btn-close" @click="closeSecondModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div>
                        <Bar :chart-data="chartData" :chart-options="chartOptions" :width="width1" :height="height1"
                            ref="BarMainChart" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="closeSecondModal">Закрыть</button>
                    <button class="btn btn-primary" @click="modalBack">Назад</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ExtrAndEnrichIronOresData } from '../use/ExtrAndEnrichIronOresData';
import { useStore } from 'vuex';
import TableRowComponent from '../components/itc25/TableRowComponent.vue';
import ResultComponent from '../components/itc25/ResultComponent.vue';
import { onMounted, reactive, ref, watchEffect } from 'vue';
import { Modal } from 'bootstrap'
import { Line } from 'vue-chartjs'
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, annotationPlugin, LineElement, PointElement)
import {
    Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType, PageOrientation, Table, TableCell, TableRow, VerticalAlign,
    Math, MathRun, MathSubScript, MathSubSuperScript, ImageRun, WidthType, MathSquareBrackets, MathFraction, MathSum, MathRoundBrackets
} from "docx";
import { saveAs } from 'file-saver';
import { array } from 'yup';


const store = useStore()
const years = store.state.firstPage.year.sort()
const main = store.state.activityType
const process = store.state.productionType
const showButton = ref(false)
const showResult = ref(false)
// const emit = defineEmits(['sendResult'])
// grafics
const BarMainChart = ref(null)

const width1 = ref(600)
const height1 = ref(300)
const chartData = ref({})
const chartOptions = ref({})
// grafics
const ip1 = ref(ExtrAndEnrichIronOresData[process].ip1)
const ip2 = ref(ExtrAndEnrichIronOresData[process].ip2)
const inputDataArr = ref(ExtrAndEnrichIronOresData[process].inputDataArr)
const outputDataArr = ref(ExtrAndEnrichIronOresData[process].outputDataArr)
const resultReactObj = ref()
const resultObj = {
    total: {},
    totalMid: 0,
    totalP: {},
    totalPMid: 0,
    directEmission: {},
    directEmissionMid: 0,
    directEmissionP: {},
    directEmissionPMid: 0,
    electroEmission: {},
    electroEmissionMid: 0,
    electroEmissionP: {},
    electroEmissionPMid: 0,
    teplotEmission: {},
    teplotEmissionMid: 0,
    teplotEmissionP: {},
    teplotEmissionPMid: 0,
    productWeight: {},
    productWeightMid: 0,
    udelDirectEmission: {},
    udelDirectEmissionMid: 0,
    udelDirectEmissionP: {},
    udelDirectEmissionPMid: 0,
    udelElectroEmission: {},
    udelElectroEmissionMid: 0,
    udelElectroEmissionP: {},
    udelElectroEmissionPMid: 0,
    udelTeploEmission: {},
    udelTeploEmissionMid: 0,
    udelTeploEmissionP: {},
    udelTeploEmissionPMid: 0,
    intensity: {},
    intensityMid: 0,
    intensityP: {},
    intensityPMid: 0
}
const mod_state = reactive({
    modal_demo: null,
    modal_demo_2: null
})

onMounted(() => {
    mod_state.modal_demo = new Modal('#exampleModal', {
        backdrop: 'static'
    })
    mod_state.modal_demo_2 = new Modal('#exampleModalToggle2', {
        backdrop: 'static'
    })
})

const modalValue = ref(null)
function openModal() {
    modalValue.value = resultReactObj.value
    mod_state.modal_demo.show()
    showGraf()
}
function openSecondModal() {
    showGraf()
    closeModal()
    mod_state.modal_demo_2.show()

}
function closeSecondModal() {
    mod_state.modal_demo_2.hide()
}

function closeModal() {
    modalValue.value = null
    mod_state.modal_demo.hide()
}
function modalBack() {
    closeSecondModal()
    openModal()
}

for (let key = 0; key < years.length; key++) {
    for (let i = 0; i < inputDataArr.value.length; i++) {
        inputDataArr.value[i].volume[years[key]] = 0
        inputDataArr.value[i].emiss[years[key]] = 0
        inputDataArr.value[i].percents[years[key]] = 0
    }
    for (let i = 0; i < outputDataArr.value.length; i++) {
        outputDataArr.value[i].volume[years[key]] = 0
        outputDataArr.value[i].emiss[years[key]] = 0
        outputDataArr.value[i].percents[years[key]] = 0
    }
    // resultObj.total[years[key]] = 0
}

function getData(event) {
    for (let i = 0; i < inputDataArr.value.length; i++) {
        if (inputDataArr.value[i].name == event.name) {
            inputDataArr.value[i].volumeMid = event.consumptionMid
            inputDataArr.value[i].emissMid = event.emissionsMid
        }
    }
    for (let i = 0; i < outputDataArr.value.length; i++) {
        if (outputDataArr.value[i].name == event.name) {
            outputDataArr.value[i].volumeMid = event.consumptionMid
            outputDataArr.value[i].emissMid = event.emissionsMid
        }
    }
    console.log(inputDataArr.value)
    resultObj.directEmissionMid = 0
    resultObj.directEmissionPMid = 0
    resultObj.electroEmissionMid = 0
    resultObj.electroEmissionPMid = 0
    resultObj.teplotEmissionMid = 0
    resultObj.teplotEmissionPMid = 0
    resultObj.totalMid = 0
    resultObj.totalPMid = 0
    resultObj.udelDirectEmissionMid = 0
    resultObj.udelElectroEmissionMid = 0
    resultObj.udelTeploEmissionMid = 0
    resultObj.intensityMid = 0
    resultObj.intensityPMid = 0
    resultObj.productWeightMid = 0
    for (let key = 0; key < years.length; key++) {
        resultObj.total[years[key]] = 0
        resultObj.directEmission[years[key]] = 0
        resultObj.directEmissionP[years[key]] = 0
        resultObj.electroEmission[years[key]] = 0
        resultObj.electroEmissionP[years[key]] = 0
        resultObj.teplotEmission[years[key]] = 0
        resultObj.teplotEmissionP[years[key]] = 0
        resultObj.totalP[years[key]] = 100
        resultObj.intensityP[years[key]] = 100
    }
    for (let key = 0; key < years.length; key++) {
        for (let i = 0; i < inputDataArr.value.length; i++) {
            resultObj.total[years[key]] += inputDataArr.value[i].main ? inputDataArr.value[i].emiss[years[key]] : 0
        }
        for (let i = 0; i < outputDataArr.value.length; i++) {
            resultObj.total[years[key]] += outputDataArr.value[i].main ? outputDataArr.value[i].emiss[years[key]] : 0
        }
    }
    for (let key = 0; key < years.length; key++) {
        for (let i = 0; i < outputDataArr.value.length; i++) {
            resultObj.productWeight[years[key]] = outputDataArr.value[0].volume[years[key]]
        }
        for (let i = 0; i < inputDataArr.value.length; i++) {

            inputDataArr.value[i].percents[years[key]] = resultObj.total[years[key]] != 0 ? (inputDataArr.value[i].emiss[years[key]] / resultObj.total[years[key]]) * 100 : 0
            resultObj.directEmission[years[key]] += inputDataArr.value[i].name == 'Дизельное топливо' || inputDataArr.value[i].name == 'Мазут' || inputDataArr.value[i].name == 'Природный газ' ?
                inputDataArr.value[i].emiss[years[key]] : 0
            // resultObj.directEmissionMid += inputDataArr.value[i].name == 'Дизельное топливо' || inputDataArr.value[i].name == 'Мазут' || inputDataArr.value[i].name == 'Природный газ' ? Number(resultObj.directEmission[years[key]]) : 0

            resultObj.directEmissionP[years[key]] += inputDataArr.value[i].name == 'Дизельное топливо' || inputDataArr.value[i].name == 'Мазут' || inputDataArr.value[i].name == 'Природный газ' ?
                inputDataArr.value[i].percents[years[key]] : 0

            resultObj.electroEmission[years[key]] += inputDataArr.value[i].name == 'Электроэнергия' ? inputDataArr.value[i].emiss[years[key]] : 0
            resultObj.electroEmissionP[years[key]] += inputDataArr.value[i].name == 'Электроэнергия' ? inputDataArr.value[i].percents[years[key]] : 0

            resultObj.teplotEmission[years[key]] += inputDataArr.value[i].name == 'Теплоэнергия' ? inputDataArr.value[i].emiss[years[key]] : 0
            resultObj.teplotEmissionP[years[key]] += inputDataArr.value[i].name == 'Теплоэнергия' ? inputDataArr.value[i].percents[years[key]] : 0

            resultObj.udelDirectEmission[years[key]] = resultObj.productWeight[years[key]] != 0 ? resultObj.directEmission[years[key]] / resultObj.productWeight[years[key]] : 0
            resultObj.udelElectroEmission[years[key]] = resultObj.productWeight[years[key]] != 0 ? resultObj.electroEmission[years[key]] / resultObj.productWeight[years[key]] : 0
            resultObj.udelTeploEmission[years[key]] = resultObj.productWeight[years[key]] != 0 ? resultObj.teplotEmission[years[key]] / resultObj.productWeight[years[key]] : 0
            resultObj.intensity[years[key]] = resultObj.udelDirectEmission[years[key]] + resultObj.udelElectroEmission[years[key]] + resultObj.udelTeploEmission[years[key]]
            resultObj.udelDirectEmissionP[years[key]] = resultObj.intensity[years[key]] != 0 ? resultObj.udelDirectEmission[years[key]] / resultObj.intensity[years[key]] * 100 : 0
            resultObj.udelElectroEmissionP[years[key]] = resultObj.intensity[years[key]] != 0 ? resultObj.udelElectroEmission[years[key]] / resultObj.intensity[years[key]] * 100 : 0
            resultObj.udelTeploEmissionP[years[key]] = resultObj.intensity[years[key]] != 0 ? resultObj.udelTeploEmission[years[key]] / resultObj.intensity[years[key]] * 100 : 0
        }
        // console.log(resultObj.directEmission)
        // resultObj.directEmissionMid = resultObj.directEmissionMid / years.length
        // console.log(resultObj.directEmissionMid)

    }
    for (let key = 0; key < years.length; key++) {
        resultObj.directEmissionMid += resultObj.directEmission[years[key]]
        resultObj.electroEmissionMid += resultObj.electroEmission[years[key]]
        resultObj.teplotEmissionMid += resultObj.teplotEmission[years[key]]
        resultObj.totalMid += resultObj.total[years[key]]
        resultObj.productWeightMid += Number(resultObj.productWeight[years[key]])
        resultObj.udelDirectEmissionMid += Number(resultObj.udelDirectEmission[years[key]])
        resultObj.udelElectroEmissionMid += Number(resultObj.udelElectroEmission[years[key]])
        resultObj.udelTeploEmissionMid += Number(resultObj.udelTeploEmission[years[key]])
        resultObj.intensityMid += Number(resultObj.intensity[years[key]])
    }
    resultObj.directEmissionMid = resultObj.directEmissionMid / years.length
    resultObj.electroEmissionMid = resultObj.electroEmissionMid / years.length
    resultObj.teplotEmissionMid = resultObj.teplotEmissionMid / years.length
    resultObj.totalMid = resultObj.totalMid / years.length
    resultObj.productWeightMid = resultObj.productWeightMid / years.length
    resultObj.udelDirectEmissionMid = resultObj.udelDirectEmissionMid / years.length
    resultObj.udelElectroEmissionMid = resultObj.udelElectroEmissionMid / years.length
    resultObj.udelTeploEmissionMid = resultObj.udelTeploEmissionMid / years.length
    resultObj.intensityMid = resultObj.intensityMid / years.length


    showButton.value = true
    resultReactObj.value = resultObj
    // emit('sendResult', resultReactObj.value)
}

function showGraf() {
    let lab = Object.keys(modalValue.value.intensity)//labels для графика
    lab.push("Среднее")
    let dat = Object.values(modalValue.value.intensity)//данные для графика
    dat.push(modalValue.value.intensityMid)

    var gColors = []//массив для цветов графика
    for (let i = 0; i < dat.length; i++) {
        if (i == dat.length - 1) {
            gColors.push('#0559C9')
        } else {
            gColors.push('#72A6FB')
        }
    }
    chartData.value = {
        labels: lab,
        datasets: [
            {
                backgroundColor: '#72A5FB',
                backgroundColor: '#72A5FB',
                data: dat
            }
        ]
    }
    chartOptions.value = {

        barThickness: 50,
        plugins: {
            legend: {
                display: false,
            },
            autocolors: false,
            annotation: {
                annotations: {//две горизонтальные линии на графике
                    line1: {
                        type: 'line',
                        yMin: ip2.value,
                        yMax: ip2.value,
                        borderColor: "#00b050",
                        borderWidth: 2,
                        label: {
                            content: ip2.value + " (ИП 2)",
                            display: true,
                            color: "#00b050",
                            height: 50
                        }
                    },
                    line2: {
                        type: 'line',
                        yMin: ip1.value,
                        yMax: ip1.value,
                        borderColor: "#ff7f27",
                        borderWidth: 2,
                        label: {
                            content: ip1.value + " (ИП 1)",
                            display: true,
                            color: "#ff7f27"
                        }
                    }
                }
            }
        }
    }
}

const exportDocx = () => {

    const canvas = document.getElementById('bar-chart');//элемент канвас с графиком
    const dataURL = canvas.toDataURL();//получаем ссылку на файл графика

    const doc = new Document({
        styles: {
            paragraphStyles: [
                {
                    paragraph: {
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        indent: {
                            left: 100
                        },
                    },
                    id: "ftp",
                },
                {
                    id: "usualPar",
                    name: "My Paragraf Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        color: "000000",
                        size: 22
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 240
                        },
                        indent: {
                            left: 420,
                        },
                    },
                },
                {
                    id: "tabCellStyle",
                    name: "Table Cell Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        color: "000000",
                        size: 16,
                    },
                    paragraph: {
                        alignment: AlignmentType.CENTER,
                    }
                },
                {
                    id: "formula",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        color: "000000",
                        size: 22
                    },
                    paragraph: {
                        alignment: AlignmentType.CENTER,
                    }
                }

            ],
        },
        sections: [
            {
                properties: {
                    page: {
                        size: {
                            orientation: PageOrientation.LANDSCAPE,
                        },
                        margin: {
                            top: "0.5in",
                            bottom: "0.5in",
                            left: "0.8in",
                            right: "0.8in"
                        }
                    },
                },
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Расчет выбросов ПГ для предприятий по добыче и обогащению железных руд",
                                bold: true,
                                size: 32,
                                color: "000000",
                            })
                        ],
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: 0,
                            after: 300
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Объект:  ",
                                bold: true,
                                size: 28,
                                color: "000000",
                            }),
                            new TextRun({
                                text: store.state.firstPage.company_name,
                                size: 28,
                                color: "000000",
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 240
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Код ОНВ:  ",
                                bold: true,
                                size: 28,
                                color: "000000",
                            }),
                            new TextRun({
                                text: store.state.firstPage.onv_code,
                                size: 28,
                                color: "000000",
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 240
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "1. Методика проведения расчета выбросов ПГ (СО₂)",
                                bold: true,
                                size: 28,
                                color: "000000",
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 240
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Расчет произведен по методике ГОСТ Р 113.16.02-2024. Наилучшие доступные технологии. Методические рекомендации по проведению бенчмаркинга удельных выбросов парниковых газов для горнодобывающей промышленности",
                                bold: true,
                                size: 24,
                                color: "000000",
                            })
                        ],
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,
                        spacing: {
                            before: 240,
                            after: 240
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Таблица 1 – Границы технологических процессов и их описание (добыча и обогащение железных руд)",
                                // bold: true,
                                size: 22,
                                color: "000000",
                            }),
                        ],
                        spacing: {
                            before: 240,
                            after: 240
                        },
                    }),
                    new Table({
                        width: {
                            size: 100,
                            type: WidthType.PERCENTAGE,
                        },
                        // indent: {
                        //     size: 600,
                        //     type: WidthType.DXA,
                        // },
                        rows: [
                            new TableRow({
                                tableHeader: true,
                                cantSplit: true,
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Наименование технологического процесса",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            })
                                        ]
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Описание границ",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            })
                                        ]
                                    }),
                                ],
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Добыча железных руд открытым способом",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                indent: {
                                                    left: 100
                                                },
                                            })
                                        ],
                                        verticalAlign: VerticalAlign.CENTER
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "В границы расчета выбросов ПГ от добычи железных руд открытым способом, включаются производственные процессы:",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- буровые работы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- взрывные работы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- погрузочно-разгрузочные работы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- транспортирование горной массы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- отвалообразование и складирование;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- первичное дробление;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- отгрузка продукции;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- эксплуатация установок газо- и водоочистки;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- водоотведение (откачка дренажной воды из карьера).",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                        ]
                                    })
                                ]
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Добыча железных руд подземным способом",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                indent: {
                                                    left: 100
                                                },
                                            })
                                        ],
                                        verticalAlign: VerticalAlign.CENTER
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "В границы расчета выбросов ПГ от добычи железных руд подземным способом, включаются производственные процессы:",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- буровые работы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- взрывные работы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- погрузочно-разгрузочные работы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- транспортирование горной массы;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- отвалообразование и складирование;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- первичное дробление;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- отгрузка продукции;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- эксплуатация установок газо- и водоочистки;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- водоотведение (откачка дренажной воды из шахты).",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                        ]
                                    })
                                ]
                            }),
                            new TableRow({
                                cantSplit: true,
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Обогащение железных руд - мокрые методы обогащения",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                indent: {
                                                    left: 100
                                                },
                                            })
                                        ],
                                        verticalAlign: VerticalAlign.CENTER
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "В границы расчета выбросов ПГ от обогащения железных руд мокрыми методами, включаются производственные процессы:",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- подготовительные процессы (дробление, измельчение, грохочение, классификация);",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- обогащение (гравитационная, магнитная сепарация; флотация, электрическая сепарация, специальные методы обогащения);",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- вспомогательные процессы (обезвоживание (сгущение и фильтрование);",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- сушка;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- транспортирование руды, концентрата и продуктов обогащения,",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- погрузочно-разгрузочные работы, складирование;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- классификация, сгущение и транспортирование хвостов; ",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- отгрузка продукции; ",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- эксплуатация установок газо- и водоочистки.",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                        ]
                                    })
                                ]
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Обогащение железных руд - сухие методы обогащения",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                indent: {
                                                    left: 100
                                                },
                                            })
                                        ],
                                        verticalAlign: VerticalAlign.CENTER
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "В границы расчета выбросов ПГ от обогащения железных руд сухими методами, включаются производственные процессы:",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- подготовительные процессы (дробление, измельчение, грохочение, классификация);",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- обогащение (гравитационная, сухая магнитная сепарация);",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- вспомогательные процессы (пылеулавливание);",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- транспортирование руды, концентрата и продуктов обогащения, погрузочно-разгрузочные работы, складирование;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- фракционирование, транспортирование сухих хвостов;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- отгрузка продукции;",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "- эксплуатация установок газо- и водоочистки.",
                                                        // bold: true,
                                                        size: 18,
                                                        color: "000000",
                                                    })
                                                ],
                                                style: "ftp"
                                            }),
                                        ]
                                    })
                                ]
                            }),
                        ]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Расчет удельных выбросов диоксида углерода для производственного процесса выполняют по формуле (1):"
                            })
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            })
                                        ],
                                    }),
                                    new MathRun(" = "),
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    }),
                                    new MathRun(" + "),
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                    new MathRun(" + "),
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun("          (1)"),
                        ],
                        style: "formula"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("где:"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun(" – удельный выброс для определенного производственного процесса, т СО2/ед. изм. продукции;"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun(" – удельные прямые выбросы в границах производственного процесса, т СО2/ед. изм. продукции;"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun(" – удельные выбросы, связанные с потреблением электроэнергии в границах производственного процесса, т СО2/ед. изм. продукции;"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun(" – удельные выбросы, связанные с потреблением тепловой энергии в границах производственного процесса, т СО2/ед. изм. продукции."),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Расчет удельных прямых выбросов СО2 в границах производственного процесса (передела) выполняется по формуле (2):"
                            })
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubSuperScript({
                                        children: [new MathRun("E")],
                                        superScript: [new MathRun("*")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    }),
                                    new MathRun(" = "),
                                    new MathSquareBrackets({
                                        children: [
                                            new MathSum({
                                                children: [
                                                    new MathRoundBrackets({
                                                        children: [
                                                            new MathSubScript({
                                                                children: [new MathRun("R")],
                                                                subScript: [new MathRun("вх.i")],
                                                            }),
                                                            new MathRun(" × "),
                                                            new MathSubScript({
                                                                children: [new MathRun("C")],
                                                                subScript: [new MathRun("вх.i")],
                                                            }),
                                                        ]
                                                    })
                                                ],
                                            }),

                                        ],
                                    }),
                                    new MathRun(" × 3,664"),

                                    new TextRun("          (2)"),
                                ]
                            })

                        ],
                        style: "formula"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("где:"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("R")],
                                        subScript: [new MathRun("вх.i")],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: " – удельный объем использования i-го углеродсодержащего ресурса в границах производственного процесса (передела) металлургической продукции (на входе в передел), ед. изм. (т, тыс. м3 и др.)/т продукции;"
                            })
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("C")],
                                        subScript: [new MathRun("вх.i")],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: " – содержание углерода в i-м углеродсодержащем ресурсе, т С/ед. изм. (т, тыс. м3 и др.)."
                            })
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Расчет удельных выбросов СО2, связанных с электроэнергией, выполняется по формуле (3):"
                            })
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                    new MathRun(" = "),
                                    new MathSubScript({
                                        children: [new MathRun("P")],
                                        subScript: [new MathRun("потр.")],
                                    }),
                                    new MathRun(" × "),
                                    new MathSubScript({
                                        children: [new MathRun("EF")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: "           (3)"
                            })
                        ],
                        style: "formula"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("где:"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("P")],
                                        subScript: [new MathRun("потр.")],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: " – удельное потребление электроэнергии в границах производственного процесса (передела), МВт∙ч/т продукции;"
                            }),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("EF")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: " – коэффициент выброса для электроэнергии, т СО2/МВт∙ч."
                            }),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Расчет удельных выбросов СО2, связанных с тепловой энергией, выполняется по формуле (4):"
                            })
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                    new MathRun(" = "),
                                    new MathSubScript({
                                        children: [new MathRun("Q")],
                                        subScript: [new MathRun("потр.")],
                                    }),
                                    new MathRun(" × "),
                                    new MathSubScript({
                                        children: [new MathRun("EF")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: "           (4)"
                            })
                        ],
                        style: "formula"
                    }),
                    new Paragraph({
                        children: [
                            new TextRun("где:"),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("Q")],
                                        subScript: [new MathRun("потр.")],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: " – удельное потребление тепловой энергии (в паре и горячей воде) в границах производственного процесса (передела), Гкал/т продукции;"
                            }),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("EF")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                ]
                            }),
                            new TextRun({
                                text: " – коэффициент выброса для тепловой энергии, т СО₂/Гкал."
                            }),
                        ],
                        style: "usualPar"
                    }),
                    new Paragraph({
                        pageBreakBefore: true,
                        children: [
                            new TextRun({
                                text: "1. Исходные данные и результаты проведения расчета выбросов ПГ (СО₂)",
                                bold: true,
                                size: 28,
                                color: "000000",
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 240
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: store.state.activityType + " - " + store.state.productionType,
                                bold: true,
                                size: 32,
                                color: "000000",
                            })
                        ],
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                        spacing: {
                            before: 0,
                            after: 300
                        },
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Входные и выходные потоки ресурсов, показатели выбросов СО₂ по видам ресурсов",
                                bold: true,
                                italics: true,
                                color: "000000",
                                size: 22
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 20
                        },
                    }),
                    new Table({
                        rows: dataTableRows()
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Итоговые абсолютные валовые и удельные выбросы СО₂ по производственному процессу (переделу), т СО₂",
                                bold: true,
                                italics: true,
                                color: "000000",
                                size: 22
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 20
                        },
                    }),
                    new Table({
                        rows: resOneTableRows()
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Итоговые удельные выбросы СО₂ по производственному процессу (переделу), т СО₂/ т продукции",
                                bold: true,
                                italics: true,
                                color: "000000",
                                size: 22
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 20
                        },
                    }),
                    new Table({
                        rows: resTwoTableRows()
                    }),
                    new Paragraph({
                        pageBreakBefore: true,
                        children: [
                            new TextRun({
                                text: "Индикативные показатели удельных выбросов парниковых газов в соответствии с ИТС 25 «Добыча и обогащение железных руд»",
                                bold: true,
                                italics: true,
                                color: "000000",
                                size: 22
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 20
                        },
                    }),
                    new Table({
                        rows: [
                            new TableRow(({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Производственный процесс (передел)"
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER
                                            })
                                        ],
                                        rowSpan: 2
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Индикативный показатель удельных выбросов парниковых газов, т СО₂-экв./ т продукции"
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER
                                            })
                                        ],
                                        columnSpan: 2
                                    }),
                                ],
                            })),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Нижний уровень индикативного показателя(ИП 2)"
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER,
                                                indent: {
                                                    right: 100
                                                }
                                            })
                                        ]
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: "Верхний уровень индикативного показателя (ИП 1)"
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER,
                                                indent: {
                                                    right: 100
                                                }
                                            })
                                        ]
                                    })
                                ]
                            }),
                            new TableRow({
                                children: [
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: store.state.activityType + " - " + store.state.productionType
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER,
                                                indent: {
                                                    right: 100
                                                }
                                            })
                                        ]
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: String(ip2.value)
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER
                                            })
                                        ]
                                    }),
                                    new TableCell({
                                        children: [
                                            new Paragraph({
                                                children: [
                                                    new TextRun({
                                                        text: String(ip1.value)
                                                    })
                                                ],
                                                style: "ftp",
                                                alignment: AlignmentType.CENTER
                                            })
                                        ]
                                    }),
                                ]
                            })
                        ]
                    }),
                    new Paragraph({
                        // pageBreakBefore: true,
                        children: [
                            new TextRun({
                                text: "Показатели удельных выбросов СО₂, т / т продукции Объекта ОНВ на графике",
                                bold: true,
                                italics: true,
                                color: "000000",
                                size: 22
                            })
                        ],
                        spacing: {
                            before: 240,
                            after: 20
                        },
                    }),
                    new Paragraph({
                        children: [
                            new ImageRun({
                                data: dataURL,
                                transformation: {
                                    width: 700,
                                    height: 400,
                                },
                            })
                        ]
                    })
                ]
            },

        ]
    })
    const mimeType =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    const fileName = "test.docx";
    Packer.toBlob(doc).then((blob) => {
        const docblob = blob.slice(0, blob.size, mimeType);
        saveAs(docblob, fileName);
    });
    function resTwoTableRows() {
        let arr = []
        arr.push(
            new TableRow({
                children: headerOne()
            }),
            new TableRow({
                children: udelDirectEmission()
            }),
            new TableRow({
                children: udelElectroEmission()
            }),
            new TableRow({
                children: udelTeploEmission()
            }),
            new TableRow({
                children: intensity()
            }),
        )
        return arr
    }
    function intensity() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("I")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            })
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Интенсивность выбросов (бенчмарк) для определенного вида металлургической продукции")]
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.intensity[years[i]].toFixed(4)))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.intensityMid.toFixed(4)))
                ]
            })
        )
        return arr
    }
    function udelTeploEmission() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Удельные выбросы, связанные с тепловой энергией")]
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.udelTeploEmission[years[i]].toFixed(4)))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.udelTeploEmissionMid.toFixed(4)))
                ]
            })
        )
        return arr
    }
    function udelElectroEmission() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Удельные выбросы, связанные с электроэнергией")]
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.udelElectroEmission[years[i]].toFixed(4)))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.udelElectroEmissionMid.toFixed(4)))
                ]
            })
        )
        return arr
    }
    function udelDirectEmission() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubSuperScript({
                                        children: [new MathRun("E")],
                                        superScript: [new MathRun("*")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Удельные прямые выбросы в границах производства ")]
            })
        )
        console.log(modalValue.value)
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.udelDirectEmission[years[i]].toFixed(4)))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.udelDirectEmissionMid.toFixed(4)))
                ]
            })
        )
        return arr
    }
    function resOneTableRows() {
        let arr = []
        arr.push(
            new TableRow({
                children: headerOne()
            }),
            new TableRow({
                children: directEmission()
            }),
            new TableRow({
                children: electroEmission()
            }),
            new TableRow({
                children: teplotEmission()
            }),
            new TableRow({
                children: total()
            }),
        )
        return arr
    }
    function total() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph("")
                ]
            }),
            new TableCell({
                children: [new Paragraph("Валовые выбросы")]
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.total[years[i]].toFixed(4)))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.totalMid.toFixed(4)))
                ]
            })
        )
        return arr
    }
    function teplotEmission() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",тепл.")
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Выбросы, связанные с тепловой энергией")]
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.teplotEmission[years[i]]))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.teplotEmissionMid))
                ]
            })
        )
        return arr
    }
    function electroEmission() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubScript({
                                        children: [new MathRun("E")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",электр.")
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Выбросы, связанные с электроэнергией")]
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.electroEmission[years[i]]))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.electroEmissionMid))
                ]
            })
        )
        return arr
    }
    function directEmission() {
        let arr = []
        arr.push(
            new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new Math({
                                children: [
                                    new MathSubSuperScript({
                                        children: [new MathRun("E")],
                                        superScript: [new MathRun("*")],
                                        subScript: [
                                            new MathSubScript({
                                                children: [new MathRun("CO")],
                                                subScript: [
                                                    new MathRun("2")
                                                ],
                                            }),
                                            new MathRun(",прям.")
                                        ],
                                    }),
                                ]
                            })
                        ]
                    })
                ]
            }),
            new TableCell({
                children: [new Paragraph("Прямые выбросы в границах производства (передела) без учета вторичных топливных газов")]
            })
        )
        console.log(modalValue.value)
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [
                        new Paragraph(String(modalValue.value.directEmission[years[i]]))
                    ]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [
                    new Paragraph(String(modalValue.value.directEmissionMid))
                ]
            })
        )
        return arr
    }
    function headerOne() {
        let arr = yearsColsOne()
        arr.unshift(
            new TableCell({
                children: [new Paragraph("")]
            }),
            new TableCell({
                children: [new Paragraph("")]
            })
        )
        return arr
    }
    function dataTableRows() {
        let arr = []
        arr.push(
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph("Вид ресурса")],
                        rowSpan: 2,
                    }),
                    new TableCell({
                        children: [new Paragraph("Единицы измерения")],
                        rowSpan: 2,
                    }),
                    new TableCell({
                        children: [new Paragraph("Суммарный израсходованный объем, ед.")],
                        columnSpan: years.length + 1
                    }),
                    new TableCell({
                        children: [new Paragraph("Коэффициент выбросов, т СО2/ед")],
                        rowSpan: 2,
                    }),
                    new TableCell({
                        children: [new Paragraph("Абсолютные валовые выбросы СО2, т")],
                        columnSpan: years.length + 1
                    }),
                ],
            })
        )

        arr.push(
            new TableRow({
                children: yearsCols()
            })
        )
        arr.push(new TableRow({
            children: [new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Входной поток",
                                italics: true
                            })
                        ],
                        style: "tabCellStyle",
                        alignment: AlignmentType.LEFT
                    })
                ],
                columnSpan: years.length * 2 + 3
            })]
        }))
        for (let i = 0; i < inputDataArr.value.length; i++) {
            arr.push(new TableRow({
                children: dataCols(inputDataArr.value[i])
            }))
        }
        arr.push(new TableRow({
            children: [new TableCell({
                children: [
                    new Paragraph({
                        children: [
                            new TextRun({
                                text: "Выходной поток",
                                italics: true
                            })
                        ],
                        style: "tabCellStyle",
                        alignment: AlignmentType.LEFT
                    })
                ],
                columnSpan: years.length * 2 + 3
            })]
        }))
        for (let i = 0; i < outputDataArr.value.length; i++) {
            arr.push(new TableRow({
                children: dataCols(outputDataArr.value[i])
            }))
        }
        return arr
    }
    function dataCols(val) {
        console.log(val)
        let arr = []

        arr.push(
            new TableCell({
                children: [new Paragraph(val.name)],
            }),
            new TableCell({
                children: [new Paragraph(val.units)],
            }),
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [new Paragraph(val.volume[Number(years[i])])]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [new Paragraph(String(val.volumeMid))]
            }),
            new TableCell({
                children: [new Paragraph(String(val.koeff))],
            }),
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [new Paragraph(String(val.emiss[Number(years[i])]))]
                })
            )
        }
        arr.push(
            new TableCell({
                children: [new Paragraph(String(val.emissMid))]
            })
        )
        return arr
    }
    function yearsColsOne() {
        let arr = []
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [new Paragraph(String(years[i]))],
                }),
            )
        }
        arr.push(
            new TableCell({
                children: [new Paragraph("среднее")],
            })
        )
        return arr
    }
    function yearsCols() {
        let arr = []
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [new Paragraph(String(years[i]))],
                }),
            )
        }
        arr.push(
            new TableCell({
                children: [new Paragraph("среднее")],
            })
        )
        for (let i = 0; i < years.length; i++) {
            arr.push(
                new TableCell({
                    children: [new Paragraph(String(years[i]))],
                }),
            )
        }
        arr.push(
            new TableCell({
                children: [new Paragraph("среднее")],
            })
        )
        return arr
    }
}

</script>

<style scoped>
.blue {
    background-color: rgba(88, 158, 244, 0.221);
}

.grey {
    background-color: rgba(206, 198, 198, 0.222);
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1054;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    background-color: black;
    opacity: .5;
    top: 0;
    left: 0;
}

.big-chart {
    opacity: 0;
    position: absolute;
    top: -500px;
}
</style>